<template>
    
    <div :class="className" :style="style">
        
        <ProfileMainPhotoComponent :profile="twist.profiles[1]" :size="60" class="position-absolute rounded-circle top-50 start-0"
            style="transform: translate(75%,-50%)!important;border:1px white solid"
        ></ProfileMainPhotoComponent>
        <ProfileMainPhotoComponent :profile="twist.profiles[0]" :size="60" class="position-absolute rounded-circle top-50 start-0"
            style="transform: translate(10%,-50%)!important;border:1px white solid"
        ></ProfileMainPhotoComponent>
        
        <ProfileMainPhotoComponent v-if="myPair" :profile="myPair" :size="30" class="position-absolute rounded-circle top-50 start-0"
            style="transform: translate(135%,30%)!important;border:1px white solid"
        ></ProfileMainPhotoComponent>
    </div>
       
</template>
<script setup>
import ProfileMainPhotoComponent from './ProfileMainPhotoComponent.vue'
import {defineProps, ref, computed} from "vue";
const props = defineProps(['twist', 'class', 'style','myPair'])
const twist=ref(props.twist);
const className=computed(()=>{return props.class})
const myPair=computed(()=>{return props.myPair})
const style=computed(()=>{return props.style})
</script>