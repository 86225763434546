import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";
import axios from "axios"

export const useSearchStore = defineStore("search", {
    state: () => ({
      twists:[],
      search_error:null
    }),
    getters: {
      getTwists(state){
          return state.twists
        }
    },
    actions: {
      async search() {
        try {
            this.search_error=null;
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/search/', getHeaders())
            this.twists= data.data
            
          }
          catch (error) {
            if( error.code=="ERR_BAD_REQUEST" && error.response.data.reason){
              this.search_error=error.response.data.reason;
            }else{
              alert(error)
              console.log(error)
            }
        }
      },
    },
})