<template>
    
    <div>
        <div style="text-align:center" class="position-relative">
            <img src="twist_logo.png" style="height:40px" />
            

            <div class="position-absolute top-0 end-0"  v-if="selectedLike!=null">
                <div class="btn-group dropstart">
                <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="handleUnlikeTwist()" >Supprimer ce match</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="reportAbuseClick()" >Signaler la discussion</a></li>
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="handleBlockTwist()" >Bloquer la paire</a></li>
                </ul>
                </div>
            </div>

        </div>
        <div v-if="selectedLike==null">
        <h1>Messages</h1>
            
            <div v-if="validated && validated.length==0">
                <div class=" text-center" style="margin-top:100px">
                    Pas de <b class="twist">TWISTS</b> validés pour le moment...

                </div>
                <div class=" text-center" style="margin-top:100px">
                    Forme <a href="javascript:void(0)" @click="()=>{router.push('/invitation')}">plus de paires</a> avec tes amis pour augmenter tes chances de rencontres !
                </div>
            </div>

            <div v-for="like,likeIndex in validated" :key="likeIndex" @click="handleViewLikeMessages(like)">
                
                <TwistListViewComponent 
                    :twist="getBestTwist(like, profile)"
                    :lastMessage="like.lastMessage"
                    :nbUnreadMessages="like.nbUnreadMessages"
                    :blocked="like.blocked"
                    :myPair="(like.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)>=0})[0] || like.twists[1]).profiles.filter(p=>p._id!=profile._id)[0]"        
                    ></TwistListViewComponent>
                    
            </div>
        
        </div>



        <div v-if="selectedLike">
            <h1>Messages</h1>
            
            <div class="d-flex mt-3" style="margin-top:5px; margin-bottom:5px">
                <div>
                    <a href="javascript:void(0)" @click="selectedLike=null" class="btn btn-outline-primary mt-3">
                        <i class="bi bi-chevron-left"></i>
                    </a>
                </div>
                <span data-bs-toggle="modal" data-bs-target="#exampleModalViewTwist" class="d-flex">
                    <TwistPhotoGroupComponent 
                        :twist="getBestTwist(selectedLike, profile)" 
                        :myPair="(selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)>=0})[0]|| selectedLike.twists[1]).profiles.filter(p=>p._id!=profile._id)[0]"
                        class="position-relative" style="height:70px; width:115px"></TwistPhotoGroupComponent>
                    <div class="mt-4 text-capitalize fw-bold" v-if="selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0]">
                        {{ selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0].profiles[0].firstName }} &
                        {{ selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0].profiles[1].firstName }} 
                    </div>
                    <div class="mt-4 text-capitalize fw-bold" v-if="selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0}).length==0">
                        {{ selectedLike.twists[0].profiles.filter(p=>{ return p._id!=profile._id})[0].firstName }} &
                        {{ selectedLike.twists[1].profiles.filter(p=>{ return p._id!=profile._id})[0].firstName }}
                    </div>
                </span>
            </div>
            <hr>
            <div style="overflow-y: scroll;" :style="{'height':maxScrollableHeight}" class="messages-box">
                <div><a href="javascript:void(0)" v-if="hasNextMessages"
                    @click="handleFetchMoreMessages(selectedLike._id)">Voir plus de messages</a></div>
                <div v-for="message , messageIndex in messages[selectedLike._id]" :key="messageIndex" >
                    <div class="text-center"
                        v-if="messageIndex==0 ||
                        new Date( message.date).getDay()!= new Date(messages[selectedLike._id][messageIndex-1].date).getDay()
                        "
                    >
                    <span class="badge rounded-pill text-bg-light">
                    {{['Dimanche', 'Lundi', 'Mardi', 'Mercredi','Jeudi', 'Vendredi', 'Samedi'][new Date( message.date).getDay()] }}
                    </span>
                </div>
                    <div
                        v-if="messageIndex==0 || messages[selectedLike._id][messageIndex-1].from != message.from"
                    :class="{'text-start': message.from!= profile._id, 'text-end':message.from== profile._id}">
                        <span v-if=" mapProfiles[message.from]">{{ mapProfiles[message.from].firstName }}</span> 
                    </div>
                    <div  :class="{
                        'text-start': message.from!= profile._id, 
                        'text-end':message.from== profile._id, 
                        'text-same':messageIndex>0 && messages[selectedLike._id][messageIndex-1].from == message.from,
                        'text-end-same':messageIndex>0 && messages[selectedLike._id][messageIndex-1].from == message.from && (messageIndex==messages[selectedLike._id].length-1 || messages[selectedLike._id][messageIndex+1].from != message.from)
                        }">
                        <span class="badge rounded-pill bg-primary">{{ message.text }} <i class="float-end text-nowrap " style="color:lightgrey;font-size:10px;padding-top: 5px;padding-left: 10px;">&nbsp;{{ formatDate(message.date) }}</i></span>
                    </div>
                    
                </div>
                <div ref="scrollToMe"></div>
            </div>
            <div class="" style="border-top:1px #EFEFEF solid;" v-if="selectedLike && selectedLike.blocked!=true">
                
                <div class="input-group mb-3 mt-3 ">
                    
                    <textarea v-model="newMessage" class="form-control" placeholder="votre message..." rows="3"></textarea>
                    <a href="javascript:void(0)" class="btn btn-outline-secondary" style="color:#F27721 ;background-color:white" @click="handleSendMessage">
                        <i class="bi bi-send"></i>
                    </a>
                    
                </div>
            
            </div>
        
        </div>


        <!-- Modal view twist detail-->
        <div class="modal fade"  data-bs-backdrop="static"  id="exampleModalViewTwist" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog ">
                <div class="modal-content " v-if="selectedLike">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="exampleModalLabel">Ton <span class="twist">Twist</span> avec
                            <span class="mt-4 text-capitalize fw-bold" v-if="selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0]">
                                {{ selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0].profiles[0].firstName }} &
                                {{ selectedLike.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0].profiles[1].firstName }} 
                            </span>
                        </h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" >
                        <TwistComponent  :twist="getBestTwist(selectedLike, profile)"></TwistComponent>                
                    </div>
            
                </div>
            </div>
        </div>
        
    </div>
</template>
<script setup>
import TwistListViewComponent from './components/TwistListViewComponent'
import TwistPhotoGroupComponent from './components/TwistPhotoGroupComponent'
import TwistComponent from './components/TwistComponent.vue'
//import { useTwistsStore} from "./stores/twists";
import { useLikesStore} from "./stores/likes";
import { useTwistsStore} from "./stores/twists";
import { useProfileStore} from "./stores/profiles";
import { onMounted, computed, ref,/*, watch*/ onUpdated} from 'vue'
import { useRouter } from 'vue-router'
import { useTokenStore } from "./stores/tokens";
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
//const twistsStore = useTwistsStore();
const likesStore= useLikesStore();
const router = useRouter();
const tokenStore= useTokenStore();
const profileStore=useProfileStore();

const twistsStore = useTwistsStore();
const messages= computed(()=>{
    //console.log('messages computed', likesStore.messages)
    return likesStore.messages;
});
const hasNextMessages=computed(()=>{
    return likesStore.hasNextMessages
})
// watch(messages.value, (newValue,oldValue)=>{
//     console.log('watch messages', newValue, oldValue)
//     if( newValue.length>0 && oldValue.length==0){
//         console.log('nouveau messages affiches')
//         console.log('scrollToMe', scrollToMe)
//     }
// })
//const messages=ref([])
const handleBlockTwist=async()=>{
    var otherTwist=getBestTwist(selectedLike.value, profile.value);
    await likesStore.blockTwistOfLike(selectedLike.value._id, otherTwist._id)
    selectedLike.value=null;
    likesStore.fetchValidated();
    window.scrollTo(0,0);

}
const reportAbuseClick=async ()=>{
    var otherTwist=getBestTwist(selectedLike.value, profile.value);
    // console.log('otherTwist', otherTwist)
    // console.log('selectedLike', selectedLike.value)
    await twistsStore.reportAbuse(otherTwist._id, selectedLike.value._id)
    alert("cette discussion a été signalée au modérateur");

}
const validated = computed(()=>{
    return likesStore.validated;
});
const scrollToMe = ref(null);
const newMessage=ref('');
const selectedLike=ref();
const mapProfiles=ref({});
const profile= computed(()=>{
    return profileStore.profile;
});

const getBestTwist=(like, profile)=>{
    var allProfilesWitoutMe=[...like.twists[0].profiles.filter(p=>p._id!=profile._id), ...like.twists[1].profiles.filter(p=>p._id!=profile._id)];
    //console.log('allProfilesWitoutMe', allProfilesWitoutMe);
    if( allProfilesWitoutMe.length==2){
        //on est dans un cas A + B <=> B + C
        return {profiles:allProfilesWitoutMe};
    }
    return like.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)<0})[0];
    
}
// const getBestPair=(like,profile)=>{
//     //(like.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)>=0})[0] || like.twists[1]).profiles.filter(p=>p._id!=profile._id)[0]

//     var allProfilesWitoutMe=[...like.twists[0].profiles.filter(p=>p._id!=profile._id), ...like.twists[1].profiles.filter(p=>p._id!=profile._id)];
//     console.log('allProfilesWitoutMe', allProfilesWitoutMe);
//     if( allProfilesWitoutMe.length==2){
//         //on est dans un cas A + B <=> B + C
//         return like.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)>=0})[0].profiles.filter(p=>p._id==profile._id)[0];
//     }
//     return like.twists.filter(t=>{ return t.profileIds.indexOf(profile._id)>=0})[0].profiles.filter(p=>p._id!=profile._id)[0]
// }

const viewLastMessages=ref(true);
// const validated= computed(()=>{
//     return twistsStore.validated;
// });
const maxScrollableHeight=ref('30rem')
const formatDate=(dateString)=>{
    //console.log('formatDate', dateString)
    const date = dayjs(dateString);
    return date.format('HH:mm')
    //console.log('date', date);
        // Then specify how you want your dates to be formatted
    //return date.format('dddd MMMM D, YYYY');
    //return date.fromNow()
}

const handleUnlikeTwist=async ()=>{
    await likesStore.unLike(selectedLike.value);
    likesStore.fetchValidated();
    selectedLike.value=null;
}
const handleFetchMoreMessages=(selectedLikeId)=>{
    if( messages.value[selectedLikeId] && messages.value[selectedLikeId].length>0){
        var lastMessageDate= messages.value[selectedLikeId][0].date
        viewLastMessages.value=false;
        likesStore.fetchLastMessages(selectedLikeId,lastMessageDate);
    }    
}
const handleViewLikeMessages=async (like)=>{
    mapProfiles.value=like.twists.reduce((c,i)=>{
        c[i.profiles[0]._id]=i.profiles[0];
        c[i.profiles[1]._id]=i.profiles[1];
        return c;
    },{});
    viewLastMessages.value=true;
    //console.log('mapProfiles', mapProfiles.value)
    likesStore.fetchLastMessages(like._id, null);
    
    selectedLike.value=like;
    // console.log('scrollToMe', scrollToMe)
    // if (scrollToMe.value) {
    //     console.log('scrolling')
    //     // Use el.scrollIntoView() to instantly scroll to the element
    //     scrollToMe.value.scrollIntoView({behavior: 'smooth'});
    // }
   
}

const handleSendMessage=()=>{
    likesStore.sendNewMessage(selectedLike.value._id, newMessage.value)
    newMessage.value='';
}

onMounted(() => {
    if( !tokenStore.isLoggedIn){
        
        router.push('/')
    }else{
        likesStore.fetchValidated();
        maxScrollableHeight.value=(window.innerHeight -450)+'px'
        window.scrollTo(0,0);
  }
})
onUpdated(()=>{
    // console.log('scrollToMe', scrollToMe)
    // console.log('messages',messages.value)
    if(viewLastMessages.value && scrollToMe.value){
        scrollToMe.value.scrollIntoView({behavior: 'smooth'});
    }
    
})

</script>

<style scoped>
.container-pair .profile{
    border:3px orange dashed; 
    padding:2px
}
.container-pair .profile.ANSWERED{
    border:3px green solid; 
}
.container-pair-name div{font-weight: bold;}
.container-pair-name div.is_me{font-style: italic;font-weight: normal;}

.messages-box .badge{
    padding: 0.5em 0.75em;
    font-size: 1em;
    white-space: normal;
    margin-bottom:0.2rem
}
.messages-box .text-end .badge{
    border-bottom-right-radius: 5px!important;
    text-align: left;
}
.messages-box .text-start .badge{
    border-bottom-left-radius: 5px!important;
    text-align: left;
}
.messages-box .text-start.text-same .badge{
    border-top-left-radius: 5px!important;
}
.messages-box .text-end.text-same .badge{
    border-top-right-radius: 5px!important;
}

.messages-box .text-end.text-same.text-end-same .badge{
    border-radius: 50px !important;
    border-top-right-radius: unset !important;
}

.messages-box .text-start.text-same.text-end-same .badge{
    border-radius: 50px !important;
    border-top-left-radius: unset !important;
}
</style>