<template>
    <div :style="styleBackgroundImage" :class="className"></div>
</template>
<script setup>
    import { usePhotosStore} from "../stores/photos";
    import {defineProps,computed, watch} from "vue";
    const props = defineProps(['profile', 'size', 'class', 'ratio', 'height'])
    const profile= computed(()=>{
        photosStore.fetchMainPhotoFromProfile(props.profile);
         return props.profile});
    const size= computed(()=>{ return props.size});
    const ratio= computed(()=>{ return props.ratio ||1});
    const height= computed(()=>{ return props.height});
    const className= computed(()=>{ return props.class});
    const photosStore= usePhotosStore();
    const photos= computed(()=>{
        //console.log("computed photos ")
        return photosStore.photos;
    });
    
    const styleBackgroundImage=computed(()=>{
        return [
                'background-image:url("'+(photos.value[profile.value.main_photo]??'photo-missing.jpg')+'");',
                'width:'+((size.value!= null)?size.value+'px':'100%'),
                'height:'+((height.value!= null)?height.value+'px':(size.value*ratio.value)+'px'),
                'background-size:cover',
                'background-position: center;'
            ].join(';');
    })

    watch(profile, (newProfile, oldprofile)=>{
        if( newProfile.main_photo!= oldprofile.main_photo){
            //console.log("watch profile ", profile.value)
            photosStore.fetchMainPhotoFromProfile(profile.value);
        }
    })
    
    // const getImgStyle=()=>{
    //     return 'width:'+size.value+'px;height:'+size.value+'px';
    // }
</script>