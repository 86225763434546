import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";

import axios from "axios"


function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - base64String.length % 4) % 4);
  var base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const useProfileStore = defineStore("profile", {
    state: () => ({
        profile:{
            nickName:'lorem'
        },
        main_photo:'',
        search_results:[],
        currentUserAgent:'N/A'
    }),
    getters: {
      getProfile(state){
        return state.profile
      },
    },
    actions: {
      async enableSubscription(){
        navigator.serviceWorker.ready
        .then(async function(registration) {
          // Get the server's public key
          const response = await axios.get(process.env.VUE_APP_ROOT_API+'/subscription/vapidPublicKey', getHeaders());
          const vapidPublicKey = await response.data
          // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
          // urlBase64ToUint8Array() is defined in /tools.js
          const convertedVapidKey = urlBase64ToUint8Array(vapidPublicKey);
          // Subscribe the user
          return registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: convertedVapidKey
          });
        }).then(function(subscription) {
            axios.post(process.env.VUE_APP_ROOT_API+'/profile/register-subscription', {subscription: subscription}, getHeaders())
        }).catch((err,i)=>{/*alert(err);*/ console.log('subscribe err', err,i)})
      },
      async disableSubscription(){
        try {
            await axios.delete(process.env.VUE_APP_ROOT_API+'/profile/register-subscription', getHeaders())
            
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async fetchCurrentUserAgent(){
        try {
         
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/ua')
            this.currentUserAgent= data.data
         
          }catch(ex){
            console.log('fetchCurrentUserAgent', ex)
          }
         
      },
      async fetchMainPhoto(){
        try {
          if( this.profile.main_photo && this.profile.main_photo!=''){
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/profile/'+this.profile._id+'/photos/'+ this.profile.main_photo, getHeaders())
            this.main_photo= data.data
          }
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async search(query){
        try {
          const data = await axios.post(process.env.VUE_APP_ROOT_API+'/profile/search', query, getHeaders())
            this.search_results= data.data
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      
      async uploadNewFile(fileInfo){
        try {
           await axios.post(process.env.VUE_APP_ROOT_API+'/profile/photos', fileInfo, getHeaders()/* {headers:{'Authorization':'Bearer '+ token.value}}*/)  
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async deleteProfile(){
        try {
          const data = await axios.delete(process.env.VUE_APP_ROOT_API+'/profile/', getHeaders())
          console.log('profileDeleted', data);
        }
        catch (error) {
          alert(error)
          console.log(error)
      }
      },
      async fetchProfile() {
        this.main_photo='';
        try {
            let data = await axios.get(process.env.VUE_APP_ROOT_API+'/profile/'/*+id*/, getHeaders()/* {headers:{'Authorization':'Bearer '+ token.value}}*/)
            //console.log('fetchProfile', data)
            if(data.code=="ERR_BAD_REQUEST" && data.response.status==401){
              return;
            }
            if( data.data.photos==null){
              data.data.photos=[]
            }
            this.profile= data.data
            this.fetchMainPhoto();
          }
          catch (error) {
            //alert(error)
            console.log(error)
        }
      },
      async saveProfile(profile) {
        
        try {
         
          const response = profile._id===undefined ? await axios.post(process.env.VUE_APP_ROOT_API+'/profile/',profile) : await axios.patch(process.env.VUE_APP_ROOT_API+'/profile/'+profile._id,profile, getHeaders())
          
            this.profile= response.data
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async updateCurrentLocation(latLong){
        try {
            axios.patch(process.env.VUE_APP_ROOT_API+'/profile/location',latLong , getHeaders())
        }catch (error) { 
            console.log(error)
        }
      }

    },
})