import { defineStore } from 'pinia'
import axios from "axios"


export const useCitiesStore = defineStore("cities", {
    state: () => ({
      matched:[],
    }),
    getters: {
      getMatched(state){
          return state.matched
        }
    },
    actions: {
      async nearby(longLat){
         
        try {
          const data = await axios.get(process.env.VUE_APP_ROOT_API+'/cities/near?long='+longLat.long+'&lat='+longLat.lat)
          return data.data
        }
        catch (error) {
          alert(error)
          console.log(error)
      }
      },
      async searchFor(value) {
        
        try {
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/cities?q='+value)
            this.matched= data.data
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      

    },
})