<template>
<div class="d-flex align-items-start">
    <img :src="photos[profile.main_photo]" class="rounded-circle me-3" style="width:50px;height:50px"/>
    <div >
        <span class="fw-bold">{{profile.firstName}}</span> - {{ profile.age }} Ans - {{ profile.city }}
        <div>
            {{ formatDate( profile.last_login_date) }}
        </div>
    </div>
    
</div>
</template>
<script setup>
import { usePhotosStore} from "../stores/photos";
//import {formatDate} from '../mixins/helpers'
import {defineProps, computed} from "vue";
import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
const props = defineProps(['profile'])
const profile=computed(()=>{return props.profile});

const photosStore= usePhotosStore();
//watch(profile, (value)=>{
    photosStore.fetchMainPhotoFromProfile(profile.value);
//});

const photos= computed(()=>{
    return photosStore.photos;
});

const formatDate=(dateString)=>{
    //console.log('formatDate', dateString)
    const date = dayjs(dateString);
    //console.log('date', date);
        // Then specify how you want your dates to be formatted
    //return date.format('dddd MMMM D, YYYY');
    return date.fromNow()
}
</script>