<template>
    <div>
        <div style="text-align:center" class="position-relative">
            <img src="twist_logo.png" style="height:40px" />
        </div>
    <div v-if="selectedTwist==null">
        <h1>Mes paires
            <div class="float-end" style="font-size:12px;color:gray;transform:translate(00px, 15px)"
            data-bs-toggle="modal" data-bs-target="#exampleModalEdit" @click="editField='nickName'"># Twister ID: {{ profile.nickName }} <i class="bi bi-chevron-compact-right"></i></div>
        </h1> 
        <div v-for="twist,itemIndex in myTwists" :key="itemIndex" >

            <div class="d-flex justify-content-center mt-3 mb-3 p-3 rounded-4 position-relative shadow" style="background-color: #FAECE3;overflow:hidden;padding-right:30px!important"
                @click="handleSelectTwist(twist)"
                >
                <div class="text-center">
                    <ProfileMainPhotoComponent :profile="twist.profiles[0]" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                    <b>{{twist.profiles[0].firstName}}</b>
                </div>
                <img src="cross.png" style="max-height:100px"/>
                <div class="text-center">
                    <ProfileMainPhotoComponent :profile="twist.profiles[1]" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                    <b>{{twist.profiles[1].firstName}}</b>
                </div>
                <div style="background-color: #FDDAC2;height:100%;min-width:30px" class="position-absolute top-50 end-0 fs-1 translate-middle-y">
                    <i class="bi bi-chevron-compact-right position-absolute top-50 end-0 fs-1 translate-middle-y"></i>    
                </div>
            </div>
            
        </div>
        <div v-for=" i in [0,1,2]" :key="i">
            <div class="d-flex justify-content-center mt-3 mb-3 p-3 rounded-4 position-relative " style="background-color: #FAECE3;border:1px dashed gray;min-height:132px"
               v-if="i+ (myTwists||[]).length<3" >
                <i class="bi bi-people fs-1" style="margin-top:27px" v-if="!(i==0 && (myTwists||[]).length==0)"></i>
                <div v-if="i==0 && (myTwists||[]).length==0">
                    <h4 class="alert-heading">Trouvez votre Paire !</h4>
                    <p>Vous avez créé votre profil, maintenant formez une paire avec un(e) ami(e) pour des rencontres 2 fois plus authentiques.</p>
                    
                </div>
            </div>
        </div>
        <!-- Modal edit profile fields (twister id)-->
  <div class="modal fade"  data-bs-backdrop="static"  id="exampleModalEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-bottom" style="align-items:flex-end">
      <div class="modal-content overflow-hidden rounded-4">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Mon profil <span class="twist">Twist</span></h1>
          <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
        </div>
        <div class="modal-body">

            <div v-if="editField=='nickName'">
            <div class="mb-4">Renseigne ton <span class="twist">#TwisterId</span> pour que tes amis te retrouvent plus facilement</div>  
            <div class="form-floating mb-4" >
              <input type="text" class="form-control" id="floatingInput" placeholder="Activité" v-model="profile.nickName" >
              <label for="floatingInput"><i class="bi bi-hash"></i> Mon Twister ID</label>
            </div>
          </div>

        </div>
        <div class="modal-footer flex-nowrap p-0 border-top">
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 py-3 m-0 rounded-0 border-end" data-bs-dismiss="modal"
            @click="profileStore.fetchProfile()"
          >
            <i class="bi bi-arrow-left-short"></i>
            Retour
          </button>
          <button type="button" class="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 twist fw-bold" data-bs-dismiss="modal"
                     @click="enregistrerUserClick"
          >
            Valider
<svg width="40" height="40" viewBox="0 0 60 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask><g ><path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#F27721"/>
</g><mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/></mask><g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#F27721"/>
</g></g><defs><clipPath id="clip0_1732_1229"><rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/></clipPath>
</defs></svg>
          </button>
        </div>
      </div>
    </div>
  </div>


        
    <div class="mt-3">
        <h3>Inviter un(e) ami(e) à former une paire</h3>
    
        <a href="javascript:void(0)" @click="handleShareInvite" class="btn btn-primary twist d-block">Inviter un(e) ami(e) <i class="bi bi-share"></i></a>
        <div class="text-center">ou</div>
        <div class="input-group mb-3">
            <input type="text" v-model="invite_nickname" class="form-control" placeholder="Recherche un #TwisterID "/>
            <a href="javascript:void(0)" class="btn btn-outline-secondary d-block" @click="handleSearchClick">
                <i class="bi bi-search"></i>
            </a>
        
        </div>
       
        <ul class="list-group">  
            <li v-for="profile, profileIndex in search_results" :key="profileIndex" class="list-group-item  position-relative">
                <ProfileListViewComponent :profile="profile"></ProfileListViewComponent>
                <a href="javascript:void(0)" @click="handleInviteClick(profile._id)" 
                class="btn btn-primary position-absolute top-50 end-0 translate-middle-y fs-1"
                style="height:100%"
                >
                    <i class="bi bi-check-all"></i>
                </a>
            </li>
        </ul>
    </div>




    <div v-if="currentInvitations && currentInvitations.my_pending_requests && currentInvitations.my_pending_requests.length>0">
        <h2>Envoyées en attente de validation:</h2>
        
        <div 
            class="d-flex justify-content-center mt-3 mb-3 p-3 rounded-4 position-relative" 
            style="background-color: #FAF2F2;"
            v-for="pending, pendingIndex in currentInvitations.my_pending_requests" :key="pendingIndex"
        >
            <div class="text-center">
                <ProfileMainPhotoComponent :profile="pending.fromProfile" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                <b>{{ pending.fromProfile.firstName }}</b>
            </div>
            <img src="cross.png" style="max-height:100px"/>
            <div class="text-center">
                <ProfileMainPhotoComponent :profile="pending.toProfile" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                <b>{{ pending.toProfile.firstName }}</b>
            </div>
            <a 
                href="javascript:void(0)" 
                class="btn btn-danger d-block position-absolute top-0 start-100  rounded-circle" 
                style="transform:translate(-75%,-25%);"
                @click="handleCancelInvitation(pending._id)"
            >X</a>
        </div>

        
    </div>
    <div  v-if="currentInvitations && currentInvitations.answers_requested && currentInvitations.answers_requested.length>0">
        <h2>Reçues en attente de validation:</h2>
        
        <div 
            class="d-flex justify-content-center mt-3 p-3 rounded-4 position-relative" 
            style="background-color: #FAF2F2;margin-bottom: 60px;"
            v-for="pending, pendingIndex in currentInvitations.answers_requested" :key="pendingIndex"
        >
            <div class="text-center">
                <ProfileMainPhotoComponent :profile="pending.fromProfile" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                <b>{{ pending.fromProfile.firstName }}</b>
            </div>
            <img src="cross.png" style="max-height:100px"/>
            <div class="text-center">
                <ProfileMainPhotoComponent :profile="pending.toProfile" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
                <b>{{ pending.toProfile.firstName }}</b>
            </div>
            <a 
                href="javascript:void(0)" 
                class="text-danger position-absolute bottom-0 start-50 border shadow rounded-circle" 
                style="font-size: 3rem;
   
    transform:translate(-150%,75%);
    bottom: 5rem;
    left: 1rem;
    background: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;"
                @click="handleValidateInvitation(pending._id, false)"
            >
                <i class="bi-x "></i>
            </a>
            <a 
                href="javascript:void(0)" 
                class="twist position-absolute bottom-0 start-50 border shadow rounded-circle" 
                style="font-size: 3rem;
   
   transform:translate(50%,75%);
   bottom: 5rem;
   left: 1rem;
   background: white;
   padding-left: 0.75rem;
   padding-right: 0.75rem;"
                @click="handleValidateInvitation(pending._id, true)"
            >
                <i class="bi-check-all"></i>
            </a>
        </div>        
    </div>

    </div>


    <div class="position-relative row mt-2" v-if="selectedTwist">
        <div class="col-2">
            <a href="javascript:void(0)" @click="handleSelectTwist(null)" class="btn btn-outline-primary "><i class="bi bi-chevron-double-left"></i></a>
        </div>
        <div class="col-4">
            <a href="javascript:void(0)" @click="editSelectedTwist=false" class="btn  d-block" :class="{'btn-secondary':editSelectedTwist==false, 'btn-outline-secondary':editSelectedTwist==true}">Aperçu</a>
        </div>
        <div class="col-4">
            <a href="javascript:void(0)" @click="editSelectedTwist=true" class="btn  d-block" :class="{'btn-secondary':editSelectedTwist==true, 'btn-outline-secondary':editSelectedTwist==false}">Modifier</a>
        </div>
        <div class="col-2">
            
            <div class="float-end" >
                <div class="btn-group dropstart">
                <button type="button" class="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots-vertical"></i>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="javascript:void(0)" @click="handleRemoveTwist(selectedTwist)">Supprimer le duo avec {{ myPairProfile.firstName}}</a></li>
                </ul>
                </div>
            </div>
        </div>
        
        
    </div>
    <div v-if="selectedTwist && editSelectedTwist==true">
        <div >
            
            
            <!-- <a href="javascript:void(0)" @click="handleRemoveTwist(selectedTwist)" class="btn btn-outline-primary position-absolute top-0 end-0" style="transform:translate(0%,-150%)"><i class="bi bi-three-dots-vertical"></i></a> -->
           
            <div class="fs-1 text-capitalize ps-2 mt-3">
            <!-- <i class="bi bi-files twist pe-2"></i> -->
            <span>
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="14.1074" y="3.4978" width="13" height="18" rx="2" fill="url(#paint0_linear_1006_1471)" stroke="#F3F3F3"/>
<g filter="url(#filter0_d_1006_1471)">
<rect x="5" y="3.48926" width="13" height="18" rx="2" transform="rotate(-15 5 3.48926)" fill="url(#paint1_linear_1006_1471)"/>
<rect x="5" y="3.48926" width="13" height="18" rx="2" transform="rotate(-15 5 3.48926)" stroke="#F3F3F3"/>
</g>
<defs>
<filter id="filter0_d_1006_1471" x="0.948883" y="0.0734863" width="25.318" height="28.8535" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1006_1471"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1006_1471" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1006_1471" x1="14.8749" y1="17.8171" x2="27.9893" y2="12.7837" gradientUnits="userSpaceOnUse">
<stop offset="0.1" stop-color="#F27721"/>
<stop offset="0.6" stop-color="#DEAA23"/>
<stop offset="1" stop-color="#AEC80D"/>
</linearGradient>
<linearGradient id="paint1_linear_1006_1471" x1="5.76751" y1="17.8086" x2="18.8819" y2="12.7752" gradientUnits="userSpaceOnUse">
<stop offset="0.1" stop-color="#F27721"/>
<stop offset="0.6" stop-color="#DEAA23"/>
<stop offset="1" stop-color="#AEC80D"/>
</linearGradient>
</defs>
</svg>

            </span>
            {{selectedTwist.profiles[0].firstName}} & {{selectedTwist.profiles[1].firstName}}
        </div>
            <!-- <h1>
                Mon duo avec {{ myPairProfile.firstName}}:
            </h1>  -->
            
        </div>
        <div class="d-flex justify-content-center mt-3 mb-3 p-3 rounded-4 position-relative" style="background-color: #FAF2F2;">
            <ProfileMainPhotoComponent :profile="selectedTwist.profiles[0]" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
            <img src="cross.png" style="max-height:100px"/>
            <ProfileMainPhotoComponent :profile="selectedTwist.profiles[1]" :size="100" class="rounded-4"></ProfileMainPhotoComponent>
        </div>
        <div>
            <!-- {{ selectedTopics }}
            {{ selectedAnswers }} -->
            <!-- <hr>
            {{selectedTwist.about[myPairProfile._id]}} -->

           <!-- <pre>{{ selectedTwist }}</pre> -->
            
            <div class="mt-3 card bg-light" v-for="topicIndex in Array.from({ length: selectedTopics.length }, (_, i) => i)" :key="topicIndex">
                <div class="card-body">
                    
                    <!-- <select class="form-select border-0" :value="selectedTopics[topicIndex]" @change="(event)=>{selectedTopics[topicIndex]=event.target.value}">
                        <option v-for="t,ti in availableTopics.filter(at=>{return selectedTopics.indexOf(at)<0 ||selectedTopics[topicIndex]==at })" :key="ti" :value="t">{{t}} {{ myPairProfile.firstName}}</option>
                    </select> -->
                    
                    <button type="button" class="btn btn-secondary " style="width:100%;text-align: left;" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        {{selectedTopics[topicIndex].replace('[Nom]', myPairProfile.firstName)}}
                        <i class="bi bi-chevron-compact-right float-end"></i>
                    </button>
                    <textarea  class="form-control border-0 mt-2" v-model="selectedAnswers[selectedTopics[topicIndex]]"></textarea><!--selectedTwist.about[myPairProfile._id][selectedTopics[topicIndex]]-->
                </div>
            </div>
        
            <a href="javascript:void(0)" @click="handleSaveAboutSelectedTwist()" class="btn btn-primary d-block mt-3">Enregistrer</a>
        </div>
        
    </div>
    
    <div v-if="selectedTwist && editSelectedTwist==false">
        <!-- <a href="javascript:void(0)" @click="()=>{editSelectedTwist=true}" class="btn btn-primary d-block mt-3">Renseigner le profil de {{ myPairProfile.firstName }}</a> -->
        <TwistComponent :twist="selectedTwist"></TwistComponent>
    </div>

    <a style="display:none" href="javascript:void(0)" id="btnModalRenseignerPair" data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="btn btn-outline-primary">Resigner la pair modal</a>
    <!-- Modal renseigner profil pair-->
    <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="staticBackdropLabel">Comment ça marche ?</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div>
                    
                    <span>Complète le profil de ta paire pour obtenir plus de twists !</span>
                </div>
                
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="()=>{handleSelectTwist(myTwists[0]); editSelectedTwist=true}">Ok</button>
            </div>
            </div>
        </div>
        </div>

    <!-- Modal -->
    <div class="modal fade"  data-bs-backdrop="static"  id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content" v-if="myPairProfile">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Pour mieux décrire {{ myPairProfile.firstName }} ({{selectedTopics.length}}/{{ nbMaxQuestionPair }})</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body" >
                
                    <div v-for="topicByCategory, topicByCategoryIndex in topicsByCategory" :key="topicByCategoryIndex" class="mb-3">
                        <h2 >{{ topicByCategory.label }}</h2>
                        <ul class="list-group list-group-flush">
                            
                            <li v-for="topic, topicIndex in topicByCategory.topics" :key="topicIndex" @click="handleSelectTopic(topic)" class="list-group-item"
                            :class="{'fw-bold list-group-item-secondary':selectedTopics.indexOf(topic)>=0}">
                                <span >{{ topic.replace('[Nom]', myPairProfile.firstName) }}  </span>
                                <i class="bi bi-check float-end" v-if="selectedTopics.indexOf(topic)>=0"></i>
       
                            </li>
                        </ul>
                    </div>
                
            </div>
        
            </div>
        </div>
    </div>
    
</div>

</template>
<script setup>
import {ref} from 'vue'
import { onMounted, computed, defineProps , watch, toRaw } from 'vue'
import { useInvitationsStore} from "./stores/invitations";
import { useProfileStore} from "./stores/profiles";
import { useTwistsStore} from "./stores/twists";
import ProfileListViewComponent from './components/ProfileListViewComponent'
import TwistComponent from './components/TwistComponent.vue'
// import TwistComponent from './components/TwistComponent'
import ProfileMainPhotoComponent from './components/ProfileMainPhotoComponent.vue'
import { useRouter } from 'vue-router'
import { useTokenStore } from "./stores/tokens";
const tokenStore= useTokenStore();
const props= defineProps(['invitationId'])
const invitationId=computed(()=>{
    return props.invitationId
})
const handleShareInvite=async ()=>{

    var title='Twist avec moi !'
    var text='Forme une paire avec '+ profile.value.firstName;

    var pqi=await invitationsStore.getMyShareLink();

    var url=process.env.VUE_APP_GOOGLE_CALLBACK.replace('/callback','')+'/twist-with-'+profile.value.firstName+'?invitation='+pqi.q
    try {
        await navigator.share( {title, text, url});
        //logText('Successfully sent share');
      } catch (error) {
        console.log('Error sharing: ' + error);
      }
}

const invitationsStore= useInvitationsStore();
const profileStore=useProfileStore();
const twistsStore = useTwistsStore();
const editSelectedTwist=ref(false);
const selectedTwist=ref(null);
const myTwists= computed(()=>{
    return twistsStore.myTwists;
});
watch(
    myTwists,
    (newVal)=>{
        console.log('watch myTwists', toRaw (newVal))
        if( newVal && newVal.length>0){
            var isPairUndefined=false;
            if( newVal[0].about==null){
                isPairUndefined=true;
            }
            else{
                var myPairProfileId=newVal[0].profileIds.filter(p=>p!= profile.value._id);
                console.log('myPairProfileId',myPairProfileId)
                if( 
                    newVal[0].about[
                        newVal[0].profileIds.filter(p=>p!= profile.value._id)[0]
                    ]
                == null){
                    isPairUndefined=true;
                }else{
                    if( 
                        newVal[0].about[
                            newVal[0].profileIds.filter(p=>p!= profile.value._id)[0]
                        ].length==0){
                        isPairUndefined=true;
                    }
                }
            }
            if( isPairUndefined){
                document.getElementById("btnModalRenseignerPair").click();
                //window.alert('resenigner la pair ')
            }


        }
    }
)
const currentInvitations = computed(() => {
  return invitationsStore.currentInvitations;
});
const search_results = computed(() => {
  return profileStore.search_results;
});
const profile = computed(() => {
  return profileStore.profile;
});
const nbMaxQuestionPair=ref(3);
const myProfile=computed(()=>{
    return profileStore.profile;
})
const editField=ref('')
const profilEdition = ref(false);
const enregistrerUserClick=async ()=>{
  
  await profileStore.saveProfile(profile.value)
  //checkProfileOnboarding();
  profilEdition.value=false;
  
}
const myPairProfile= computed(()=>{
    return (selectedTwist.value||{profiles:[]}).profiles.filter(p=>p._id!=myProfile.value._id)[0];
})



const topicsByCategory=ref([
    {label:'Personnalité', topics:[
"Le talent caché de [Nom]"
,"La qualité secrète de [Nom]"
,"Le rêve de [Nom]"
,"Le week-end idéal de [Nom]"
,"La citation préférée de [Nom]"
,"La passion secrète de [Nom]"
,"Le personnage de fiction préféré de [Nom]"
,"Le rêve d’enfance de [Nom]"
,"Ce qui fait sourire [Nom]"
,"Le red flag de [Nom]"
,"Le green flag de [Nom]"
,"La première impression que [Nom] donne"
,"Ce qui énerve le plus [Nom]"
,"Ce que [Nom] ferait avec un million d'euros"
,"Ce qui détend [Nom]"
,"Le cocktail préféré de [Nom]"
,"Le film/série TV préféré de [Nom]"
,"L'animal spirituel de [Nom]"
    ]},
    {
        label:'Aventure', topics:[
"L’anecdote la plus folle de [Nom]"
,"La chose la plus spontanée que [Nom] ait faite"
,"La chose la plus courageuse que [Nom] ait faite"
,"Le souvenir d’enfance préféré de [Nom]"
,"Le moment le plus embarrassant de [Nom]"
,"Une aventure folle que nous avons vécue"
,"Le plus grand défi que [Nom] ait relevé"
,"Le voyage préféré de [Nom]"
,"La destination de voyage préférée de [Nom]"
,"Le rêve de voyage de [Nom]"
,"Une situation embarrassante que [Nom] a vécue"
,"Comment nous nous sommes rencontrés"
,"Une anecdote amusante à notre sujet"
,"Notre premier souvenir ensemble"
,"[Nom] n’a jamais…"
,"Deux vérités et un mensonge à propos de [Nom]"
,"Le plus grand risque que [Nom] ait pris"
,"Une chose que [Nom] ne refera jamais"
,"La pire idée que [Nom] ait jamais eue"
,"La plus grande surprise que [Nom] m'ait faite"
        ]
    }
])

const handleSelectTopic=(topic)=>{
    if( selectedTopics.value.indexOf(topic)<0){
        selectedTopics.value.push(topic)
        if( selectedTopics.value.length>nbMaxQuestionPair.value){
            selectedTopics.value=selectedTopics.value.slice(1,nbMaxQuestionPair.value+1)
        }
    }else{
        selectedTopics.value= selectedTopics.value.reduce((c,i)=>{
            if(i!=topic){
                c.push(i);
            }
            return c;},[])

    }
}
const selectedTopics=ref([
    // 'Le talent caché de',
    // 'La passion de',
    // 'Le red flag de'
])
const selectedAnswers=ref({})

const nbMinQuestions=ref(3);

// const selectedTopics= computed(()=>{
//     let temp=availableTopics.value.slice(0,3);
//     if(selectedTwist.value.about && selectedTwist.value.about[myPairProfile.value._id]){
//         var ik=0;
//         for( var k in selectedTwist.value.about[myPairProfile.value._id]){
//             if( ik<3){
//                 temp[ik]=k;
//             }
//         }
//     }
//     return temp

// })

const invite_nickname=ref('')
const handleSearchClick=()=>{
    profileStore.search({nickName:invite_nickname.value});
};
const handleInviteClick=async(id)=>{
    await invitationsStore.invite(id);
    invitationsStore.mine();
    twistsStore.mine();
    invite_nickname.value='';
    handleSearchClick();
    
};
const handleRemoveTwist=async (twist)=>{
    await twistsStore.remove(twist);
    twistsStore.mine();
    handleSelectTwist(null);
}
const handleSelectTwist=(twist)=>{
    if( twist!= null){
        
        var myPairProfilId= twist.profiles.filter(p=>p._id!=myProfile.value._id)[0]._id;
        if( twist.about && twist.about[myPairProfilId]){

            var tempTopic=[]
            var tempAnswer={};
            for(var i=0; i< twist.about[myPairProfilId].length; i++){
                tempTopic.push(twist.about[myPairProfilId][i].q)
                tempAnswer[twist.about[myPairProfilId][i].q]=twist.about[myPairProfilId][i].a
            }
            if( tempTopic.length<nbMinQuestions.value){
                var subAvailableAnswers= topicsByCategory.value[0].topics.filter(at=>{return tempTopic.indexOf(at)<0}).slice(0,nbMinQuestions.value-tempTopic.length) 
                subAvailableAnswers.forEach(element => {
                    tempTopic.push(element);
                    tempAnswer[element]='';
                });
            }
            selectedTopics.value=tempTopic;
            selectedAnswers.value=tempAnswer;

        }else{
            selectedTopics.value=topicsByCategory.value[0].topics.slice(0,3);// availableTopics.value.slice(0,3);
            selectedAnswers.value=selectedTopics.value.reduce((c,i)=>{c[i]='';return c;},{})
                
        }

        
    }
    editSelectedTwist.value=false;
    selectedTwist.value=twist
}
const handleSaveAboutSelectedTwist=async ()=>{
    var data={
        profileId:myPairProfile.value._id, 
        about:[]//selectedTwist.value.about[myPairProfile.value._id]
    };
    for(var i=0;i<selectedTopics.value.length;i++){
        //data.about[selectedTopics.value[i]]=selectedTwist.value.about[myPairProfile.value._id][selectedTopics.value[i]];
        if(selectedAnswers.value[selectedTopics.value[i]]!='' ){
            data.about.push({q:selectedTopics.value[i],a:selectedAnswers.value[selectedTopics.value[i]]})
        }
    }
    await twistsStore.saveAbout(selectedTwist.value._id,data);
    twistsStore.mine();
    handleSelectTwist(null);
}
const handleCancelInvitation=(id)=>{
    invitationsStore.cancelInvitation(id);}
const handleValidateInvitation=async (id, status)=>{
    await invitationsStore.validateInvitation(id, status);
    twistsStore.mine();
}

onMounted(() => {
    
    
    if( !tokenStore.isLoggedIn){
        
        if( invitationId.value){
            localStorage.setItem('twist.iid', invitationId.value)
        }
        const router = useRouter();
        router.push('/')
    }else{

        var iid=invitationId.value;
        if(!iid && localStorage.getItem('twist.iid')){
            iid=localStorage.getItem('twist.iid');
            localStorage.removeItem('twist.iid')
        }

        if( iid){
            invitationsStore.checkInvitation(iid);   
        }

        invitationsStore.mine();
        twistsStore.mine();
        window.scrollTo(0,0);
    }
})

</script>