import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";
import axios from "axios"

export const useInvitationsStore = defineStore("invitations", {
    state: () => ({
      currentInvitations:[]

    }),
    getters: {
      getCurrentInvitations(state){
          return state.currentInvitations
        }
    },
    actions: {
      async checkInvitation(invitation){
        try{
          await axios.post(process.env.VUE_APP_ROOT_API+'/validate-share-link',{invitation:invitation}, getHeaders())
          this.mine();
        }catch(ex){
          console.log(ex);
        }
        
      },
      async getMyShareLink(){
        try{
        const data = await axios.get(process.env.VUE_APP_ROOT_API+'/my-share-link', getHeaders())
        return data.data;
        }catch(e){
          console.log(e)
        }
        return null;
      },
      async cancelInvitation(id){
        try {
          const data = await axios.delete(process.env.VUE_APP_ROOT_API+'/invitations/'+id, getHeaders())
          console.log('data',data)
          this.mine();
        }
        catch (error) {
          alert(error)
          console.log(error)
        }
      },
      async validateInvitation(id, status){
        try {
          const data = await axios.patch(process.env.VUE_APP_ROOT_API+'/invitations/'+id, {validated:status}, getHeaders())
          console.log('data',data)
          this.mine();
        }
        catch (error) {
          //alert(error)
          if( error.code=="ERR_BAD_REQUEST" && error.response.status==406 && error.response.data.reason=="TOO_MANY_TWIST"){
            alert('Vous avez atteint la limite de paires pour votre compte')
          }
          console.log(error)
        }
      },
      async invite(nickName){
        try {
          const data = await axios.post(process.env.VUE_APP_ROOT_API+'/invitations/',{to:nickName}, getHeaders())
          console.log('data',data)
          this.mine();
        }
        catch (error) {
          alert(error)
          console.log(error)
        }
      },
      async mine() {
        
        try {
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/invitations/', getHeaders())
            this.currentInvitations= data.data
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      

    },
})