import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";
import axios from "axios"

const defaultImg="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FtZXJhIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xNSAxMmExIDEgMCAwIDEtMSAxSDJhMSAxIDAgMCAxLTEtMVY2YTEgMSAwIDAgMSAxLTFoMS4xNzJhMyAzIDAgMCAwIDIuMTItLjg3OWwuODMtLjgyOEExIDEgMCAwIDEgNi44MjcgM2gyLjM0NGExIDEgMCAwIDEgLjcwNy4yOTNsLjgyOC44MjhBMyAzIDAgMCAwIDEyLjgyOCA1SDE0YTEgMSAwIDAgMSAxIDF6TTIgNGEyIDIgMCAwIDAtMiAydjZhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0yVjZhMiAyIDAgMCAwLTItMmgtMS4xNzJhMiAyIDAgMCAxLTEuNDE0LS41ODZsLS44MjgtLjgyOEEyIDIgMCAwIDAgOS4xNzIgMkg2LjgyOGEyIDIgMCAwIDAtMS40MTQuNTg2bC0uODI4LjgyOEEyIDIgMCAwIDEgMy4xNzIgNHoiLz4KICA8cGF0aCBkPSJNOCAxMWEyLjUgMi41IDAgMSAxIDAtNSAyLjUgMi41IDAgMCAxIDAgNW0wIDFhMy41IDMuNSAwIDEgMCAwLTcgMy41IDMuNSAwIDAgMCAwIDdNMyA2LjVhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDAgMSAxIDAiLz4KPC9zdmc+";
export const usePhotosStore = defineStore("photos", {
    state: () => ({
        photos:{}
    }),
    actions: {
      async fetchMainPhotoFromProfile(profile){
        //console.log('fetchMainPhotoFromProfile', profile)
        try {
          if( profile && profile.main_photo && profile.main_photo!=''){

            if( this.photos[profile.main_photo]!=null && this.photos[profile.main_photo]!=defaultImg){
                return;
            }
            this.photos[profile.main_photo]=defaultImg

            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/profile/'+profile._id+'/photos/'+ profile.main_photo, getHeaders())
            this.photos[profile.main_photo]= data.data;
            //console.log('photo set on', profile._id, profile.main_photo)
          }/*else{
            //console.log('profile is null', profile)
          }*/
        }catch (error) {
            // alert(error)
            // console.log(error)
        }
      },
      async fetchPhotoFromProfile(profile, index){
        
        try {
          if( profile && profile.photos && index<profile.photos.length){

            if( this.photos[profile.photos[index]]!=null){
                return;
            }
            this.photos[profile.photos[index]]="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FtZXJhIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xNSAxMmExIDEgMCAwIDEtMSAxSDJhMSAxIDAgMCAxLTEtMVY2YTEgMSAwIDAgMSAxLTFoMS4xNzJhMyAzIDAgMCAwIDIuMTItLjg3OWwuODMtLjgyOEExIDEgMCAwIDEgNi44MjcgM2gyLjM0NGExIDEgMCAwIDEgLjcwNy4yOTNsLjgyOC44MjhBMyAzIDAgMCAwIDEyLjgyOCA1SDE0YTEgMSAwIDAgMSAxIDF6TTIgNGEyIDIgMCAwIDAtMiAydjZhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0yVjZhMiAyIDAgMCAwLTItMmgtMS4xNzJhMiAyIDAgMCAxLTEuNDE0LS41ODZsLS44MjgtLjgyOEEyIDIgMCAwIDAgOS4xNzIgMkg2LjgyOGEyIDIgMCAwIDAtMS40MTQuNTg2bC0uODI4LjgyOEEyIDIgMCAwIDEgMy4xNzIgNHoiLz4KICA8cGF0aCBkPSJNOCAxMWEyLjUgMi41IDAgMSAxIDAtNSAyLjUgMi41IDAgMCAxIDAgNW0wIDFhMy41IDMuNSAwIDEgMCAwLTcgMy41IDMuNSAwIDAgMCAwIDdNMyA2LjVhLjUuNSAwIDEgMS0xIDAgLjUuNSAwIDAgMSAxIDAiLz4KPC9zdmc+"

            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/profile/'+profile._id+'/photos/'+ profile.photos[index], getHeaders())
            this.photos[profile.photos[index]]= data.data;
            //console.log('photo set on', profile._id, profile.main_photo)
          }/*else{
            console.log('profile is null', profile)
          }*/
        }catch (error) {
            // alert(error)
            // console.log(error)
        }
      },
    }
})