import { defineStore } from 'pinia'
import axios from "axios"
import { storeToRefs } from 'pinia'

export const  getHeaders=()=>{
  const tokenStore = useTokenStore();
  const { token } = storeToRefs(tokenStore)
  return {headers:{'Authorization':'Bearer '+ token.value}};
}

axios.interceptors.response.use(function (response) {
  // Do something with response data
  //console.log('axios response' , response)
  return response;
}, function (error) {
  // Do something with response error
  console.log('axios error' ,error)
  if([/*404,*/ 401].indexOf(error.response.status )>=0) {
    const tokenStore = useTokenStore();
    tokenStore.logout()
    return error;
  }
  //throw error;
  //return error;
  return Promise.reject(error);
});


export const useTokenStore = defineStore("token", {
    state: () => ({
        isNewProfile:false,
        isLoggedIn:false,
        token:null,
        provider:null,
        challenge:null
    }),
    getters: {
      getToken(state){
          return state.token
        },
      isLoginReady(state){
        console.log('getting isLoginReady', state)
        return state.isLoggedIn &&  state.token!=null;
      }
    },
    actions: {
      storeChallenge(value){
        this.challenge=value;
      },
      async su(token){
        this.token=token;
      },
      async requestLostPassword(email){
        try{
          await axios.post(process.env.VUE_APP_ROOT_API+'/auth/lost-password',{email:email})
          return true;
        }catch(e){
          console.log(e)
          return false;
        }
      },
      async checkOtp(email, otp){
        try{
          var response=await axios.post(process.env.VUE_APP_ROOT_API+'/auth/validate-otp',{email:email, otp:otp})
          return response.data;
        }catch(e){
          console.log(e)
          return {success:false, count:-2};
        }
      },
      async requestModifyPassword(password, uuid, aid){
        try{
          await axios.post(process.env.VUE_APP_ROOT_API+'/auth/modify-password',{uuid:uuid, aid:aid, pass:password})
          return true;
        }catch(e){
          
          console.log(e)
          return false;
        }
      },
      async requestToken(email, pass){
        try{
          var response=await axios.post(process.env.VUE_APP_ROOT_API+'/auth/request-token',{email:email, pass:pass})
          localStorage.setItem('twist.token', response.data.token)
            this.isNewProfile= response.data.isNewProfile
            this.isLoggedIn=true;
            this.token=response.data.token;
            this.provider='twist';

        }catch(e){
          alert('identifiants invalides')
          console.log(e)
        }
      },
      checkIsAlreadyLoggedIn(){
        var ls= localStorage.getItem('twist.token');
        if( (ls ||'') != ''){
          this.isLoggedIn=true;
          this.token=ls;
          return true;
        }
        return false;

      },
      async logout(){
        localStorage.removeItem('twist.token')
        this.token=null;
        this.isLoggedIn=false;
        this.provider=null;
      },
      async checkLogin(provider, code, challenge) {
        
        try {
         
            const response = await axios.post(process.env.VUE_APP_ROOT_API+'/token/',{code:code ,provider:provider, challenge:challenge})
            localStorage.setItem('twist.token', response.data.token)
            this.isNewProfile= response.data.isNewProfile
            this.isLoggedIn=true;
            this.token=response.data.token;
            this.provider=provider;

            console.log("response in token store", response)
            console.log(this)
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      }

    },
})