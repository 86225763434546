<template>
  <div>
    
    <main  class="container">
      
      <RouterView />
      <div v-if="isDebug">
        <br>
        <a class="me-3 btn btn-secondary" href="javascript:void(0)" @click="su('at_nico')">Su Nico</a>
        <a class="me-3 btn btn-secondary" href="javascript:void(0)" @click="su('at_julia')">Su Julia</a>
        <a class="me-3 btn btn-secondary" href="javascript:void(0)" @click="su('at_sam')">Su Sam</a>
        <br>
      </div>
      <!-- <a href="javascript:void(0)" @click="handleSubscriptionButtonAction" :class="{'disabled':!subscriptionButtonEnabled}">{{ subscriptionButtonText }}</a> -->
      <div style="height: 85px;" v-if="isLoginReadyComputed"></div>
    </main>
    <nav class="navbar fixed-bottom shadow-lg" style="background-color: #EFEFEF;" @click="handleNavClick()">
      <div class="container d-block">
        
        <div class="" id="navbarCollapse">
          <!-- {{ $route.fullPath }} -->
        <ul class="nav nav-pills nav-fill" v-if="isLoginReadyComputed">
          
          <li class="nav-item">
            <RouterLink class="nav-link" to="/search" v-if="isLoginReadyComputed">
              
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-search" viewBox="0 -2 20 20">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
              </svg>
              <!-- Search -->
            </RouterLink>
          </li>
          
         
          <li class="nav-item position-relative">
            <RouterLink class="nav-link" to="/pendings" v-if="isLoginReadyComputed">
              <!-- <i class="bi bi-check-all"></i> -->
              
<svg width="40" height="40" viewBox="12 5 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1732_1229)">
<mask id="mask0_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="12" y="14" width="26" height="20">
<path d="M12.0001 14H37.1276V34H12.0001V14Z" fill="white"/>
</mask>
<g >
<path d="M20.5159 33.8794C19.96 33.8794 19.423 33.6421 19.0285 33.2247L12.7806 26.5644C11.9595 25.6895 11.9595 24.2736 12.7806 23.3977C13.6027 22.5228 14.9315 22.5228 15.7526 23.3977L20.5159 28.4731L33.3666 14.7814C34.1877 13.9055 35.5166 13.9055 36.3377 14.7814C37.1597 15.6563 37.1597 17.0723 36.3377 17.9472L22.0034 33.2247C21.6088 33.6451 21.0756 33.8794 20.5159 33.8794Z" fill="#ADAFBB"/>
</g>
<mask id="mask1_1732_1229" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="26" y="14" width="23" height="20">
<path d="M26.98 14H48.0001V34H26.98V14Z" fill="white"/>
</mask>
<g >
<path d="M30.1521 27.1748L31.3715 28.4731L44.2184 14.7814C45.0395 13.9055 46.3683 13.9055 47.1904 14.7814C48.0115 15.6563 48.0115 17.0723 47.1904 17.9472L32.8551 33.2247C32.4606 33.6451 31.9274 33.8794 31.3677 33.8794C30.809 33.8794 30.2748 33.6451 29.8803 33.2247L27.1772 30.3446L30.1521 27.1748Z" fill="#ADAFBB"/>
</g>
</g>
<defs>
<clipPath id="clip0_1732_1229">
<rect width="36" height="20" fill="white" transform="translate(12.0001 14)"/>
</clipPath>
</defs>
</svg>
              <span v-if="pending && pending.length>0" class="badge rounded-pill bg-danger position-absolute top-50 end-0 translate-middle-y">
                {{ pending.length }}
              </span>
              <!--pending-->
            </RouterLink>
          </li>
          <li class="nav-item position-relative">
            <RouterLink class="nav-link" to="/messages" v-if="isLoginReadyComputed">
              <!-- <i class="bi bi-chat"></i> -->
                            
              <svg width="40" height="40" viewBox="0 -3 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 12C22 17.5229 17.5229 22 12 22C9.01325 22 2 22 2 22C2 22 2 14.5361 2 12C2 6.47715 6.47715 2 12 2C17.5229 2 22 6.47715 22 12Z" fill="#ADAFBB" stroke="#ADAFBB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 9H16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 13H16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 17H12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span v-if="validated && nbUnreadMessages>0" 
                class=" badge rounded-pill bg-danger position-absolute top-50 end-0 translate-middle-y">
                {{ nbUnreadMessages }}
              </span>
                
              <!--messages-->
            </RouterLink>
          </li>
          <li class="nav-item position-relative">
            <RouterLink class="nav-link" to="/invitation" v-if="isLoginReadyComputed">
              <!-- <i class="bi bi-link"></i> -->
              <span>
                  
              <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="9.49905" y="4.49756" width="13" height="18" rx="2"  stroke="#F3F3F3"/>
                <rect x="0.391632" y="4.48926" width="13" height="18" rx="2" transform="rotate(-15 0.391632 4.48926)" fill="#ADAFBB" stroke="#F3F3F3"/>
              </svg>

              </span>
              <span v-if="currentInvitations && currentInvitations.answers_requested && currentInvitations.answers_requested.length>0" 
                class=" badge rounded-pill bg-danger position-absolute top-50 end-0 translate-middle-y">
                {{ currentInvitations.answers_requested.length }}
              </span>
              <!-- Invitation -->
            </RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link d-flex justify-content-center" to="/profile" v-if="isLoginReadyComputed" :class="{'active':['/search-preferences','/profile-hobbies'].indexOf( $route.fullPath)>=0 }">
              <!-- <i class="bi bi-person-circle"></i>profile -->
              
              <ProfileMainPhotoComponent :profile="profile" :size="40" class="rounded-circle"></ProfileMainPhotoComponent>
            </RouterLink>
          </li>
          <li class="nav-item">
            <RouterLink class="nav-link" to="/" v-if="!isLoginReadyComputed">
              <i class="bi bi-box-arrow-in-right"></i>
              Login
            </RouterLink>
            <!-- <RouterLink class="nav-link" to="/logout" v-if="isLoginReadyComputed">
              <i class="bi bi-box-arrow-right"></i>
            </RouterLink> -->
            <!-- Logout -->
          </li>
        </ul>
      </div>
    </div>
    </nav>
  </div>
</template>

<script setup>
import ProfileMainPhotoComponent from './components/ProfileMainPhotoComponent.vue'
   import { useTokenStore} from "./stores/tokens";
   import { useProfileStore} from "./stores/profiles";
   import { useInvitationsStore} from "./stores/invitations";
   import { useLikesStore} from "./stores/likes";
   import { computed , watch, onMounted, ref} from "vue";
   import { useRouter } from 'vue-router'
   const router = useRouter();
   const tokenStore = useTokenStore();

   const profileStore= useProfileStore();
   const isLoginReadyComputed= computed(() => {
    return tokenStore.isLoginReady;
  });
  const invitationsStore= useInvitationsStore();
  const likesStore= useLikesStore();
  const currentInvitations = computed(() => {
    return invitationsStore.currentInvitations;
  });
  const pending= computed(()=>{
      return likesStore.pendingValidation;
  });
  const validated = computed(()=>{
    return likesStore.validated;
  });
  const nbUnreadMessages=computed(()=>{
    return validated.value.reduce((c,i)=>{c+=i.nbUnreadMessages; return c;},0)
  })
  const tokenComputed= computed(()=>{
    return tokenStore.token;
  })
  const isDebug=ref(false)
  const navClick=ref([]);
  const handleNavClick=()=>{
    navClick.value.push(new Date().getTime())
    
    if( navClick.value.length>=3){
    navClick.value=navClick.value.slice(navClick.value.length-3,navClick.value.length)
    
      if( navClick.value[2]-navClick.value[0]<1000){
        isDebug.value=!isDebug.value;
        navClick.value=[]
      }
    }
  }
  watch( tokenComputed, (newVal)=>{
    if( newVal!=null){
      profileStore.fetchProfile();
      invitationsStore.mine();
      likesStore.fetchPendingValidation();
      likesStore.fetchValidated();
    }

  })
  const profile=computed(()=>{
    return profileStore.profile
  })
  // const subscriptionButtonText=ref('Subscribe');
  // const subscriptionButtonEnabled=ref(false);
  const initServiceWorker=()=>{
    
    // Register a Service Worker.
    navigator.serviceWorker.register('/service-worker.js', );
    
    navigator.serviceWorker.ready
    .then(function(registration) {
      console.log('service worker registered');
      //subscriptionButtonEnabled.value=true;
      //subscriptionButton.removeAttribute('disabled');
      registration.update()
      return registration.pushManager.getSubscription();
    }).then(function(subscription) {
      if (subscription) {
        console.log('Already subscribed', subscription.endpoint);
       // setUnsubscribeButton();
      } /*else {
        setSubscribeButton();
      }*/
    });

    // Get the `registration` from service worker and create a new
    // subscription using `registration.pushManager.subscribe`. Then
    // register received new subscription by sending a POST request with
    // the subscription to the server.
    

    
  }

  // const handleSubscriptionButtonAction=()=>{
  //   if(subscriptionButtonText.value=='Subscribe' ){
  //     subscribe()
  //   }else{
  //     unsubscribe();
  //   }
  // }

  // This function is needed because Chrome doesn't accept a base64 encoded string
  // as value for applicationServerKey in pushManager.subscribe yet
  // https://bugs.chromium.org/p/chromium/issues/detail?id=802280
  // function urlBase64ToUint8Array(base64String) {
  //   var padding = '='.repeat((4 - base64String.length % 4) % 4);
  //   var base64 = (base64String + padding)
  //     .replace(/-/g, '+')
  //     .replace(/_/g, '/');
  
  //   var rawData = window.atob(base64);
  //   var outputArray = new Uint8Array(rawData.length);
  
  //   for (var i = 0; i < rawData.length; ++i) {
  //     outputArray[i] = rawData.charCodeAt(i);
  //   }
  //   return outputArray;
  // }
  // Change the subscription button's text and action.
  // function setSubscribeButton() {
  //     //subscriptionButton.onclick = subscribe;
  //     //subscriptionButton.textContent = 'Subscribe!';
  //     subscriptionButtonText.value='Subscribe'
  //   }

  //   function setUnsubscribeButton() {
  //    // subscriptionButton.onclick = unsubscribe;
  //     //subscriptionButton.textContent = 'Unsubscribe!';
  //     subscriptionButtonText.value='Unsubscribe'
  //   }
  // function subscribe() {
  //   profileStore.enableSubscription();
   
  //   }

    // Get existing subscription from service worker, unsubscribe
    // (`subscription.unsubscribe()`) and unregister it in the server with
    // a POST request to stop sending push messages to
    // unexisting endpoint.
    // function unsubscribe() {
    //   navigator.serviceWorker.ready
    //   .then(function(registration) {
    //     return registration.pushManager.getSubscription();
    //   }).then(function(subscription) {
    //     return subscription.unsubscribe()
    //       .then(function() {
    //         console.log('Unsubscribed', subscription.endpoint);
    //         return fetch(process.env.VUE_APP_ROOT_API+'/unregister', {
    //           method: 'post',
    //           headers: {
    //             'Content-type': 'application/json'
    //           },
    //           body: JSON.stringify({
    //             subscription: subscription
    //           })
    //         });
    //       });
    //   }).then(setSubscribeButton);
    // }
  watch(isLoginReadyComputed, (newVal, oldValue)=>{
    
    if( newVal==true){
      
      initServiceWorker();

      navigator.geolocation.getCurrentPosition(
        /*success*/(pos) =>{
        const crd = pos.coords;
        // console.log("Your current position is:");
        // console.log(`Latitude: ${crd.latitude}`);
        // console.log(`Longitude: ${crd.longitude}`);
        // console.log(`Accuracy: ${crd.accuracy} meters.`);
        profileStore.updateCurrentLocation({long:crd.longitude, lat:crd.latitude})
      }, 
      /*error*/ (err) =>{
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }, 
      /*options*/
      {
        enableHighAccuracy: true,
        timeout: 2*5000,
        maximumAge: 0,
      }
      );
    }

    if( newVal==false && oldValue==true){
      router.push('/');
    }
  })

  const su=(at)=>{
    tokenStore.su(at);
  }
  onMounted(()=>{
    profileStore.fetchCurrentUserAgent()
  })
</script>

<style>
html{height: 100%;}
body{
  
  background: linear-gradient(0deg, rgba(243,243,243,1) 0%, rgba(255,255,255,1) 100%);
  background-repeat: no-repeat;
  background-color: rgba(243,243,243,1);
  background-attachment: fixed;
}
#app {
  font-family: "Nunito Sans",Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 10px;
}
#app h1{
  font-size: 34px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: bold;
}

#app .btn-secondary{
  color:#F27721 ;background-color: #FAECE3;border:0;
}

#app .btn-outline-secondary{border-color:#E8E6EA}
#app .fs-1{font-size:24px; font-weight: bold;}
#app .btn.btn-outline-primary{border-color:#E8E6EA;color:#F27721 ;}
#app .btn.btn-primary{border-color:#E8E6EA;background-color:#F27721 ;color:white;font-weight: bold;font-size:16px; border-radius:15px;padding-top:0.5rem; padding-bottom:0.5rem}
#app .form-control, #app .form-select{border-color:#E8E6EA; border-radius: 15px;padding-top:0.5rem; padding-bottom:0.5rem}
#app .btn , #app .list-group, #app .card{border-radius: 15px;border-color: rgb(222, 226, 230);}
#app .card{background-color:white;}
#app .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
  border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
/* #app .input-group .btn-outline-secondary{padding-top:1rem;border:0; border-radius:50px;padding-left:1rem;padding-right:1rem} */
#app .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),
#app .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
#app .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select,
#app .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating){
  border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}


/* #app .list-group{border-radius:15px} */
#app .dropstart .dropdown-toggle::before{display:none}
#app .btn-check:checked+.btn,
#app .btn.active,
#app .btn.show,
#app .btn:first-child:active,
#app :not(.btn-check)+.btn:active{
  background: white;
}
#app .bg-primary,
#app .btn.btn.btn-outline-primary:hover{
  background: #F27721!important;color:white
}

#app .form-check-input:checked {
    background-color: #F27721;
    border-color: #F27721;
}
#app .form-check-input:focus,
#app .form-control:focus,
#app .form-select:focus{border-color:#F27721;box-shadow:0 0 0 .25rem rgba(253, 133, 13, 0.25)}

#app .input-group .input-group-text{
  background: white;border:0;
}

#app .form-range::-webkit-slider-thumb{
  background-color: #F27721;
}

#app .form-range::-moz-range-thumb {
  background-color: #F27721;
}
/* #app .form-range:focus::-webkit-slider-thumb ,
#app .form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(253, 133, 13, 0.25);
}
#app .form-range::-webkit-slider-thumb:active ,
#app .form-range::-moz-range-thumb:active,
#app .form-range::-webkit-slider-thumb {
  background-color: #F27721;
} */
#app .navbar{padding:0}
#app .nav-pills .nav-link.active,
#app .nav-pills .show>.nav-link{
  background: #E8E6EA;
}
#app .navbar .nav-pills .nav-link{
  border-radius: 0;
  border-top:3px #E8E6EA solid;
  color:#ADAFBB;
  /* padding:1rem */
  /* padding-bottom: 15px; */
  padding:0.85rem;
  padding-bottom: 1.5rem;

}
#app .navbar .nav-pills .nav-link.active{
  border-radius: 0;
  border-top:3px #F27721  solid;
  color:#F27721
}

#app .container {max-width:500px;}

#app svg rect {
  fill:#ADAFBB
}
#app .active svg rect,
#app .active svg path  {
  fill:#F27721
}

#app .form-floating>label {z-index: 7;height:auto;padding-bottom:0}
#app .modal-header, #app .modal-footer{border: 0;}


#app .list-group-radio .form-check-input:checked + .list-group-item {
    background-color: var(--bs-body);
    border-color: #F27721;
    box-shadow: 0 0 0 2px #F27721;
}
#app .list-group-radio .list-group-item {
    cursor: pointer;
    border-radius: .5rem;
}
#app .list-group-radio .form-check-input {
    z-index: 2;
    margin-top: -.5em;
}
#app .input-group > input, #app .input-group > .btn{
  padding-top:1rem;
  padding-bottom:1rem
}


#app .form-floating>label{left:0.5rem}
  #app .form-control, #app .form-select{
    padding-left:1.25rem
  }
   #app .form-floating .form-control-plaintext~label,
  #app .form-floating .form-control:focus~label,
  #app .form-floating .form-control:not(:placeholder-shown)~label,
  #app .form-floating>.form-select~label,
  #app .form-floating > .input-group~label{
    transform: scale(.85) translateY(-1.75rem) translateX(.15rem);
    background: white;
    border-radius:10px
  }
  #app .modal-dialog-bottom{
    min-height:calc(100% - var(--bs-modal-margin)*2 );
    display: flex;
  }
  .zoom-btn {
           
      transition: transform 0.2s ease;
  }

  .zoom-btn:active {
      transform: scale(1.2);
  }

  #app .border-top{border-color: #F27721!important}
#app .modal-header h1{margin-top:0.25rem; margin-bottom: 0.25rem;}
#app .modal-header{border-bottom:2px #F27721 solid}
</style>
