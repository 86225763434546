<template>
    <div class="mt-3">
        <div class="fs-1 text-capitalize ps-2">
            <!-- <i class="bi bi-files twist pe-2"></i> -->
            <span>
                <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="14.1074" y="3.4978" width="13" height="18" rx="2" fill="url(#paint0_linear_1006_1471)" stroke="#F3F3F3"/>
<g filter="url(#filter0_d_1006_1471)">
<rect x="5" y="3.48926" width="13" height="18" rx="2" transform="rotate(-15 5 3.48926)" fill="url(#paint1_linear_1006_1471)"/>
<rect x="5" y="3.48926" width="13" height="18" rx="2" transform="rotate(-15 5 3.48926)" stroke="#F3F3F3"/>
</g>
<defs>
<filter id="filter0_d_1006_1471" x="0.948883" y="0.0734863" width="25.318" height="28.8535" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1006_1471"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1006_1471" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1006_1471" x1="14.8749" y1="17.8171" x2="27.9893" y2="12.7837" gradientUnits="userSpaceOnUse">
<stop offset="0.1" stop-color="#F27721"/>
<stop offset="0.6" stop-color="#DEAA23"/>
<stop offset="1" stop-color="#AEC80D"/>
</linearGradient>
<linearGradient id="paint1_linear_1006_1471" x1="5.76751" y1="17.8086" x2="18.8819" y2="12.7752" gradientUnits="userSpaceOnUse">
<stop offset="0.1" stop-color="#F27721"/>
<stop offset="0.6" stop-color="#DEAA23"/>
<stop offset="1" stop-color="#AEC80D"/>
</linearGradient>
</defs>
</svg>

            </span>
            {{twist.profiles[0].firstName}} & {{twist.profiles[1].firstName}}
        </div>
        <div style="color:#ADAFBB" class="fst-italic ps-2 pe-2">
            {{twist.profiles[0].age}} ans  / {{twist.profiles[1].age}} ans
            <div class="float-end" v-if="twist.distance">
                {{ Math.round(twist.distance/100)/10 }} km
            </div>
        </div>
        <div class="row mt-2">
            
            <div class="col-6 pe-0 position-relative">
                <!-- <ProfileComponent :profile="twist.profiles[0]"></ProfileComponent> -->
                <ProfileMainPhotoComponent :profile="twist.profiles[0]" :height="300" class="imgProfile"></ProfileMainPhotoComponent>                

            </div>
            <div class="col-6 ps-0">
                <!-- <ProfileComponent :profile="twist.profiles[1]"></ProfileComponent> -->
                <ProfileMainPhotoComponent :profile="twist.profiles[1]" :height="300" class="imgProfile"></ProfileMainPhotoComponent>
            </div>
        </div>
        
        <div class="card mt-4">
            <div class="card-body">
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ twist.profiles[0].height }} cm</div>
                    <div class="col-2 text-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.63604 14.1238L7.05026 15.538M8.46447 11.2953L9.87868 12.7096M11.2929 8.46691L12.7071 9.88113M14.1213 5.63849L15.5355 7.0527M2.80762 16.9522L7.05026 21.1948L21.1924 7.0527L16.9498 2.81006L2.80762 16.9522Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class=" col-5 text-end">{{ twist.profiles[1].height }} cm</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ twist.profiles[0].city }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-geo-alt"></i>
                    </div>
                    <div class=" col-5 text-end">{{ twist.profiles[1].city }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ twist.profiles[0].currentPosition }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-briefcase"></i>
                    </div>
                    <div class=" col-5 text-end">{{ twist.profiles[1].currentPosition }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ twist.profiles[0].astrologicalSign }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-moon-stars"></i>
                    </div>
                    <div class=" col-5 text-end">{{ twist.profiles[1].astrologicalSign }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ (twist.profiles[0].languages||[]).join(', ') }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-translate"></i>
                    </div>
                    <div class=" col-5 text-end">{{ (twist.profiles[1].languages||[]).join(', ') }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ labels[twist.profiles[0].smoking] }}</div>
                    <div class="col-2 text-center">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" enable-background="new 0 0 24 24" id="Layer_1" version="1.1" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

<g>
<path d="M20,15H1c-0.1328125,0-0.2597656,0.0527344-0.3535156,0.1464844   C0.5800781,15.2124023,0,15.8266602,0,16.9995117c0,1.1738281,0.5800781,1.7880859,0.6464844,1.8540039   C0.7402344,18.9472656,0.8671875,19,1,19h19c0.1328125,0,0.2597656-0.0527344,0.3535156-0.1464844   C20.4199219,18.7875977,21,18.1733398,21,16.9995117c0-1.1728516-0.5800781-1.7871094-0.6464844-1.8530273   C20.2597656,15.0527344,20.1328125,15,20,15z" fill="black"/>
<path d="M5.5,16.9995117C5.5,16.4920044,5.6505127,16.1704102,5.755249,16h12.9182739   C18.5786743,16.2736816,18.5,16.5901489,18.5,16.9995117c0,0.4098511,0.0787354,0.7265625,0.173645,1.0004883H5.7561035   C5.6496582,17.8272705,5.5,17.5060425,5.5,16.9995117z" fill="white"/>
<path d="M1,16.9995117C1,16.5068359,1.1455078,16.1748047,1.2495117,16h3.4240112   C4.5786743,16.2736816,4.5,16.5901489,4.5,16.9995117C4.5,17.4093628,4.5787354,17.7260742,4.673645,18H1.2504883   C1.1445312,17.8232422,1,17.4916992,1,16.9995117z" fill="white"/>
<path d="M19.7526855,17.9938965C19.6463623,17.8195801,19.5,17.5007324,19.5,16.9995117   c0-0.5028687,0.147583-0.8223267,0.2522583-0.9942017C19.8579712,16.1831665,20,16.5123291,20,16.9995117   C20,17.4870605,19.8583984,17.815918,19.7526855,17.9938965z" fill="white"/>
<path d="M22,8h-7c-0.5512695,0-1-0.4487305-1-1s0.4487305-1,1-1h6.5C21.7763672,6,22,5.7763672,22,5.5   S21.7763672,5,21.5,5H15c-1.1030273,0-2,0.8969727-2,2s0.8969727,2,2,2h7c0.5512695,0,1,0.4487305,1,1s-0.4487305,1-1,1h-1   c-1.1030273,0-2,0.8969727-2,2v0.5c0,0.2763672,0.2236328,0.5,0.5,0.5s0.5-0.2236328,0.5-0.5V13c0-0.5512695,0.4487305-1,1-1h1   c1.1030273,0,2-0.8969727,2-2S23.1030273,8,22,8z" fill="black"/>
<linearGradient gradientUnits="userSpaceOnUse" id="SVGID_1_" x1="3.6547365" x2="21.4434223" y1="8.4581614" y2="16.7531624">
<stop offset="0" style="stop-color:#FFFFFF;stop-opacity:0.2"/>
<stop offset="1" style="stop-color:#FFFFFF;stop-opacity:0"/>
</linearGradient>
<path d="M20,15H1c-0.1328125,0-0.2597656,0.0527344-0.3535156,0.1464844   C0.5800781,15.2124023,0,15.8266602,0,16.9995117c0,1.1738281,0.5800781,1.7880859,0.6464844,1.8540039   C0.7402344,18.9472656,0.8671875,19,1,19h19c0.1328125,0,0.2597656-0.0527344,0.3535156-0.1464844   C20.4199219,18.7875977,21,18.1733398,21,16.9995117c0-1.1728516-0.5800781-1.7871094-0.6464844-1.8530273   C20.2597656,15.0527344,20.1328125,15,20,15z M22,8h-7c-0.5512695,0-1-0.4487305-1-1s0.4487305-1,1-1h6.5   C21.7763672,6,22,5.7763672,22,5.5S21.7763672,5,21.5,5H15c-1.1030273,0-2,0.8969727-2,2s0.8969727,2,2,2h7   c0.5512695,0,1,0.4487305,1,1s-0.4487305,1-1,1h-1c-1.1030273,0-2,0.8969727-2,2v0.5c0,0.2763672,0.2236328,0.5,0.5,0.5   s0.5-0.2236328,0.5-0.5V13c0-0.5512695,0.4487305-1,1-1h1c1.1030273,0,2-0.8969727,2-2S23.1030273,8,22,8z" fill="url(#SVGID_1_)"/>
</g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                    </div>
                    <div class=" col-5 text-end">{{labels[ twist.profiles[1].smoking] }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ labels[twist.profiles[0].alcohol] }}</div>
                    <div class="col-2 text-center">
                        <svg width="24px" height="24px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 30.9998H25M18 30.9998V17.9998L29 6.99981H7L16 15.9998M12.2051 4.01351C11.1701 2.21251 9.2261 0.999512 7.0001 0.999512C3.6861 0.999512 1.0001 3.68651 1.0001 6.99951C1.0001 10.3135 3.6861 12.9995 7.0001 12.9995C8.6561 12.9995 10.1561 12.3275 11.2421 11.2425M29 0.999812L23 6.99981" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                    </div>
                    <div class=" col-5 text-end">{{ labels[twist.profiles[1].alcohol] }}</div>
                </div>
                <div class="row border-bottom pb-2 mb-2">
                    <div class=" col-5">{{ twist.profiles[0].searchingFor }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-search"></i>
                    </div>
                    <div class=" col-5 text-end">{{ twist.profiles[1].searchingFor }}</div>
                </div>
                <div class="row ">
                    <div class=" col-5">{{ genderLabels[twist.profiles[0].searchingForGender] }}</div>
                    <div class="col-2 text-center">
                        <i class="bi bi-heart"></i>
                    </div>
                    <div class=" col-5 text-end">{{ genderLabels[twist.profiles[1].searchingForGender] }}</div>
                </div>
            </div>
        </div>
       
        
        <div v-if="allHbobbies" class="mt-4">
            
            <!-- <h3 >Hobbies</h3> -->
            <span v-for="hobby, hobbyIndex in allHbobbies" :key="hobbyIndex" >
                <a href="javascript:void(0)"  class="btn me-3 mt-3 btn-outline-secondary twist fw-bold" style="font-size: 14px;" >
                    {{ hobby }}
                </a>
            </span> 
        </div>

        <div v-for="index in [0,1,2]" :key="index">
            <div v-for="iProfile in [0,1]" :key="iProfile">
                
                <div v-if="twist.about && twist.about[twist.profiles[iProfile]._id] && index< twist.about[twist.profiles[iProfile]._id].length && twist.about[twist.profiles[iProfile]._id][index].a">
                    <div class="card text-dark mt-4" >
                        <div class="card-body" >
                            
                            <div class="card-text" style="font-weight: 500;">
                                <div style="font-size: 16px;">{{twist.about[twist.profiles[iProfile]._id][index].q.replace('[Nom]',twist.profiles[iProfile].firstName )}} </div>
                                <div style="font-size: 24px;">{{ twist.about[twist.profiles[iProfile]._id][index].a }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="ctn-photo">
                    <ProfilePhotoComponent v-if="(index+1)<twist.profiles[iProfile].photos.length" :profile="twist.profiles[iProfile]" :index="index+1" :height="400" :description="true" class="rounded-4 mt-4 "></ProfilePhotoComponent>

                </div>
            </div>
        </div>


        <div v-for="iProfile in [0,1]" :key="iProfile">
            
            <!-- <div v-if="twist.about && twist.about[twist.profiles[iProfile]._id]">

                <div v-for="about, aboutIndex in twist.about[twist.profiles[iProfile]._id]" :key="aboutIndex">
                    <div class="card text-dark mt-3" v-if="about.a">
                        <div class="card-body" >
                            
                            <div class="card-text" style="font-weight: 500;">
                                <div style="font-size: 16px;">{{about.q.replace('[Nom]',twist.profiles[iProfile].firstName )}} :</div>
                                <div style="font-size: 24px;">{{ about.a }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- <div v-if="twist.profiles[iProfile].hobbies" >
                <span v-for="hobby, hobbyIndex in twist.profiles[iProfile].hobbies" :key="hobbyIndex" >
                    <a href="javascript:void(0)"  class="btn me-3 mt-3 btn-outline-secondary twist fw-bold" style="font-size: 14px;" >
                        {{ hobby }}
                    </a>
                </span> 
            </div> -->

            <!-- <div class="mt-3" v-if="twist.profiles[iProfile].city">
                <i class="bi-geo-alt"></i> {{ twist.profiles[iProfile].city }}
            </div> -->
<!-- 
            <div class="mt-3" v-if="twist.profiles[iProfile].currentPosition">
                <i class="bi-briefcase"></i> {{ twist.profiles[iProfile].currentPosition }}
            </div> -->
<!-- 
            <div class="mt-3" v-if="twist.profiles[iProfile].astrologicalSign">
                <i class="bi-moon-stars"></i> {{ twist.profiles[iProfile].astrologicalSign }}
            </div> -->
<!-- 
            <div class="mt-3 row" v-if="twist.profiles[iProfile].photos && twist.profiles[iProfile].photos.length>1 ">
                <div v-for="i in [1,2,3]" :key="i" class="col-4 position-relative">
                   <ProfilePhotoComponent v-if="i<twist.profiles[iProfile].photos.length" :profile="twist.profiles[iProfile]" :index="i" :size="120" :ratio="1.5" class="rounded-3  "></ProfilePhotoComponent>
                </div>
            </div> -->
        </div>

        <a href="javascript:void(0)" @click="reportAbuseClick()" class="text-danger fw-bold d-block mt-5 mb-2 text-center link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover">Signaler ce profil</a>
    </div>
</template>
<script setup>
import {defineProps, computed, ref} from "vue";
// import ProfileComponent  from './ProfileComponent.vue';
import ProfileMainPhotoComponent from './ProfileMainPhotoComponent'
import ProfilePhotoComponent from './ProfilePhotoComponent'
const props = defineProps(['twist'])
const twist=computed(()=>{return props.twist});
import { useTwistsStore} from "../stores/twists";
const twistsStore = useTwistsStore();
const labels=ref({
    y:'Oui',
    n:'Non',
    s:'Parfois'
})
const genderLabels=ref({
    F:'Femme',
    M:'Homme',
    X:'Non-binaire',
    A:'Tout le monde'
})
const allHbobbies=computed(()=>{
    if(twist.value){
        return (twist.value.profiles[0].hobbies||[]).reduce((c,i)=>{ if(c.indexOf(i)<0){c.push(i)} return c;}, (twist.value.profiles[1].hobbies||[]))
    }
    return [];
})

const reportAbuseClick=async ()=>{
    await twistsStore.reportAbuse(twist.value._id)
    alert("ce profil a été signalé au modérateur");

}
</script>
<style>
.pe-0 div.imgProfile{border-radius:20px 0 0 20px; }
.ps-0 div.imgProfile{border-radius:0 20px 20px 0}

.ctn-photo .rounded-3{width:100%}
</style>