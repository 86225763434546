import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";
import axios from "axios"

export const useLikesStore = defineStore("likes", {
    state: () => ({
      likes:[],
      pendingValidation:[],
      pendingMyPairValidation:[],
      validated:[],
      messages:{},
      hasNextMessages:false
    }),
    getters: {
      getLikes(state){
          return state.likes
        }
    },
    actions: {
      async blockTwistOfLike(likeId, twistId){
        try {
          await axios.post(process.env.VUE_APP_ROOT_API+'/likes/'+likeId+'/block-twist',{twistId:twistId}, getHeaders())
          await this.fetchLastMessages(likeId)
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async fetchPendingMyPairValidation(){
        
        try {
          const data = await axios.get(process.env.VUE_APP_ROOT_API+'/likes/waitingMyPair', getHeaders())
          this.pendingMyPairValidation= data.data
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async fetchPendingValidation(){
        
        try {
          const data = await axios.get(process.env.VUE_APP_ROOT_API+'/likes/pendingValidation', getHeaders())
          this.pendingValidation= data.data
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async sendNewMessage(likeId, text){
        try {
          await axios.post(process.env.VUE_APP_ROOT_API+'/likes/'+likeId+'/messages',{text:text}, getHeaders())
          await this.fetchLastMessages(likeId)
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async fetchLastMessages(likeId, lastMessageDate){
        //console.log("fetchLastMessages")
        this.hasNextMessages=false
        try {
          if( lastMessageDate==null){
            this.messages[likeId]=[]
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/likes/'+likeId+'/messages', getHeaders())
            //console.log('headers', data.headers)
            this.hasNextMessages=data.data.next;
            this.messages[likeId] = data.data.messages
            //return JSON.parse(JSON.stringify(this.messages[likeId]))
          }else{
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/likes/'+likeId+'/messages?last='+lastMessageDate, getHeaders())
            this.hasNextMessages=data.data.next;
            this.messages[likeId] = [...data.data.messages, ...this.messages[likeId]]
            //return JSON.parse(JSON.stringify(this.messages[likeId]))
          }
          
        }
        catch (error) {
          
            alert(error)
            console.log(error)
            //return []
        }
        
      },
      async fetchValidated(){
        
        try {
          const data = await axios.get(process.env.VUE_APP_ROOT_API+'/likes/validated', getHeaders())
          this.validated= data.data
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async unLike(like){
        try {
          /*const data =*/ await axios.delete(process.env.VUE_APP_ROOT_API+'/likes/'+ like._id, getHeaders())
          
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
      },
      
      async iDontLike(twistId) {
        
        try {
            await axios.post(process.env.VUE_APP_ROOT_API+'/likes/',{twistId:twistId, validate:false}, getHeaders())
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async iLike(twistId) {
        
        try {
            const data = await axios.post(process.env.VUE_APP_ROOT_API+'/likes/',{twistId:twistId, validate:true}, getHeaders())
            //console.log('data',data)
            this.likes= data.data
            return {success:true, isNewMatch:data.data.isNewMatch, isNewMatchValidated:data.data.isNewMatchValidated}
          }
          catch (error) {
            if( error.code=="ERR_BAD_REQUEST"){
              return   {success:false, reason:error.response.data.reason}

            }else{
              alert(error)
              console.log(error)
              return {success:false};
            }
        }
      },
      
    },
})