import { defineStore } from 'pinia'
import { getHeaders} from "./tokens";
import axios from "axios"

export const useTwistsStore = defineStore("twists", {
    state: () => ({
      myTwists:[],
      validated:[]
    }),
    getters: {
      getMyTwists(state){
          return state.myTwists
        }
    },
    actions: {
      async reportAbuse(twistId, likeId){
        try {
          console.log('report-abuse', twistId, likeId )
          await axios.post(process.env.VUE_APP_ROOT_API+'/twists/'+twistId+'/report-abuse',{likeId:likeId}, getHeaders())
         
         }
         catch (error) {
           alert(error)
           console.log(error)
       }

      },
      async saveAbout(twistId, about){

        try {
           await axios.patch(process.env.VUE_APP_ROOT_API+'/twists/'+twistId+'/about',about, getHeaders())
          
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      async remove(twist){

        try {
          await axios.delete(process.env.VUE_APP_ROOT_API+'/twists/'+twist._id, getHeaders())
          this.mine();
        }
        catch (error) {
          alert(error)
          console.log(error)
      }

      },
      async mine() {
        
        try {
            const data = await axios.get(process.env.VUE_APP_ROOT_API+'/twists/', getHeaders())
            this.myTwists= data.data
          }
          catch (error) {
            alert(error)
            console.log(error)
        }
      },
      

    },
})