<template>
    <div>
        Chargement de votre profil en cours
    </div>
</template>
<script setup>
    import { computed } from 'vue'
    import { useRoute, useRouter } from 'vue-router'
    import { onMounted } from 'vue'
    import { useTokenStore} from "./stores/tokens";
    //import { storeToRefs } from 'pinia'
    import { watch } from 'vue'

  

    const router = useRouter()
    const route = useRoute()

    const code = computed({
        get() {
            return route.query.code ?? ''
        }
    })
    const tokenStore = useTokenStore();
   
    watch(
        () => tokenStore.isLoginReady,
        () => {
            //console.log('isLoginReady changed')
            router.push('/profile')
        },
    )
    onMounted(() => {

        //console.log(`the component is now mounted.`, code.value)
        tokenStore.checkLogin("facebook", code.value);
    })

</script>