<template>
  <div v-if="profile">
    <h1>Préférences</h1>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-2 col-form-label">Genre</label>
      <div class="col-10 align-self-center">
        <div class="form-check form-check-inline" v-for="gender in ['M','F','?']" :key="gender">
          <input type="radio"
          :id="'for_'+gender"
          name="gender"
          class="form-check-input" 
          @click="()=>{searchPreference.gender=gender;}"
          :checked="gender==searchPreference.gender">

          <label :for="'for_'+gender">{{{'M':'Homme', 'F':'Femme', '?':'Tout le monde'}[gender]}}</label>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="staticEmail" class="col-2 col-form-label align-self-center">Age</label>
      <div class="col-10">

        <div class="input-group">
          <span class="input-group-text">min</span>
          <input type="number" class="form-control" v-model="searchPreference.age_min">
          <input type="number" class="form-control" v-model="searchPreference.age_max">
          <span class="input-group-text">max</span>
        </div>
        
      </div>
    </div>

    <div class="mb-3 row">
      <label for="staticEmail" class="col-2 col-form-label ">Distance</label>
      <div class="col-10 text-center pt-2">
        <input type="range" class="form-range" min="0" max="100" v-model="searchPreference.distance" />
        ({{ searchPreference.distance }} km)
      </div>
    </div>
    
    <div class="mb-3">
      <a href="javascript:void(0)" class="btn btn-primary d-block" @click="enregistrerUserClick">Enregistrer</a>
    </div>
    <hr class="mt-5 mb-2"/>


    <div class="d-flex flex-column flex-md-row pt-4 gap-4 py-md-5 align-items-center justify-content-center">
      <div class="list-group list-group-radio d-grid gap-2 border-0">

        
        <div class="position-relative" v-if="profile && profile.subscription ">
          <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value=""  @click="handleSubscribeOnDevice" :checked="profile.subscription!=null">
          <label class="list-group-item py-3 pe-5" for="listGroupRadioGrid2">
            <strong class="fw-semibold"><i class="bi bi-bell"></i> Etre informé</strong>
            <span class="d-block small opacity-75">Continuer à recevoir toutes les notifications sur <br/> {{ profile.subscription.useragent }}</span>
          </label>
        </div> 

        <div class="position-relative" v-if="profile && ((profile.subscription && currentUserAgent!=profile.subscription.useragent )||(profile.subscription==null))">
          <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid2" value="" @click="handleSubscribeOnDevice">
          <label class="list-group-item py-3 pe-5" for="listGroupRadioGrid2">
            <strong class="fw-semibold"><i class="bi bi-bell"></i>  Etre informé ici</strong>
            <span class="d-block small opacity-75">S'abonner aux notifications sur ce périphérique : {{ currentUserAgent}}</span>
          </label>
        </div> 
        
        <div class="position-relative">
          <input class="form-check-input position-absolute top-50 end-0 me-3 fs-5" type="radio" name="listGroupRadioGrid" id="listGroupRadioGrid1" value="" @click="handleUnsubscribe" :checked="profile.subscription==null">
          <label class="list-group-item py-3 pe-5" for="listGroupRadioGrid1">
            <strong class="fw-semibold"><i class="bi bi-bell-slash"></i> Ne pas déranger</strong>
            <span class="d-block small opacity-75">Ne plus recevoir de notifications pour mes invitations recues, mes likes  a valider et les nouveaux messages recus </span>
          </label>
        </div>


      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { useProfileStore } from "./stores/profiles";
import { useTokenStore } from "./stores/tokens";
import {  onMounted, computed } from "vue";
const router = useRouter();
const profileStore = useProfileStore();
const tokenStore= useTokenStore();
const profile= computed(() => {
  return profileStore.profile;
});
const currentUserAgent= computed(() => {
  return profileStore.currentUserAgent;
});
const searchPreference=computed(()=>{

    return profileStore.profile.searchPreference ;

});

const  handleSubscribeOnDevice=async()=>{
  await profileStore.enableSubscription();
  profileStore.fetchProfile();
}


const  handleUnsubscribe=async()=>{
  await profileStore.disableSubscription();
  profileStore.fetchProfile();
}
const enregistrerUserClick=async ()=>{
  await profileStore.saveProfile(profile.value)
  router.push('/search');

}
onMounted(() => {
  if( !tokenStore.isLoggedIn){
        
        router.push('/')
    }else{
      profileStore.fetchProfile();
    }
});



</script>
<style  scoped>

</style>